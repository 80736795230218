<template>
  <div >
    <div class="reservation-result-wrap">
        <div class="reservation-result" >
            <dl class="complete">
                <dt>{{ $t('content.auth.SignUpStep4.Title') }}</dt>
                <dd class="desc">
                </dd>
            </dl>
        </div>
    </div>
    <div class="btn_bottom_wrap">
      <!-- Login 버튼 -->
      <BaseButton
        @click="doNext"
      >{{ $t('content.auth.SignUpStep4.ButtonNext') }}</BaseButton>
    </div>
  </div>
</template>

<script>
import SignUpStep4 from '@/views/auth/SignUpStep4.vue';

export default {
  name: 'MobileSignUpStep4',
  extends: SignUpStep4,
};
</script>
